import React from "react"

import Layout from "../components/shared/layout"
import SEO from "../components/seo"
import Benefit from "../components/benefit"
import "../components/styles/benefits.scss"

const Benefits = () => {
  let pageDescription = "Inscrivez-vous a notre infolettre afin de profiter d’offres exclusives aux membres de la communauté Tea Rock."
  return (
  <Layout french={true}>
    <SEO title="Bénéfices" description={pageDescription} lang="fr" />
    <Benefit french={true} />
  </Layout>
)}

export default Benefits
