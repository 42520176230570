import React, { useState } from "react"
import Networking from "../images/networking.png"
import HiddenMarket from "../images/hiddenMarket.png"
import addToMailchimp from "gatsby-plugin-mailchimp"
import Text from "../text.json"
import { Container, Button } from "react-bootstrap"

const Benefit = ({ french }) => {
  const [email, setEmail] = useState(null)
  const [subscribed, setSubscribed] = useState(false)
  function handleSubmit(e) {
    e.preventDefault()
    addToMailchimp(email)
    setSubscribed(true)
  }
  return (
    <section className="benefit__section">
      <div className="benefit__header">
        <h1>{Text[french ? "fr" : "en"].benefit["heading"]}</h1>
        <p className="benefit__subheading">{Text[french ? "fr" : "en"].benefit["subheading"]}</p>
      </div>
      <Container>
        <div className="benefit__email--container">
          <h3>{Text[french ? "fr" : "en"].benefit["infoletter__heading"]}</h3>
          <p>{Text[french ? "fr" : "en"].benefit["infoletter__subheading"]}</p>
          <form className="benefit__form" onSubmit={(e) => handleSubmit(e)} >
            <input disabled={subscribed ? true : false} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your Email" type="email"></input>
  <Button disabled={subscribed ? true : false} onClick={(e) => handleSubmit(e)}>{subscribed ? "Subscribed!" : "Submit"}</Button>
          </form>
          <div className="benefit__circle benefit-top-circle"></div>
          <div className="benefit__circle benefit-bottom-circle"></div>
        </div>
      <div>
      </div>
      <div className="benefit__content">
        <div className="benefit__content-text">
  <h3>{Text[french ? "fr" : "en"].benefit["card-1__heading"]}</h3>
          <p>{Text[french ? "fr" : "en"].benefit["card-1__text"]}</p>
        </div>
        <div className="benefit__image">
          <img src={Networking} alt="A couple smiling"></img>
        </div>
      </div>
      <div className="benefit__content benefit__content--reverse">
        <div className="benefit__image">
          <img src={HiddenMarket} alt="A couple smiling"></img>
        </div>
        <div className="benefit__content-text">
          <h3>{Text[french ? "fr" : "en"].benefit["card-2__heading"]}</h3>
          <p>{Text[french ? "fr" : "en"].benefit["card-2__text"]}<a
              rel="noreferrer noopener"
              target="_blank"
              href="https://www.hec.ca/diplome/services/gestion-de-carriere/10-conseils-pour-reussir-votre-reseautage.html"
            > [1]
            </a></p>
        </div>
      </div>
      </Container>
    </section>
  )
}


export default Benefit
